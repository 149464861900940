
frappe.ui.form.Layout = class Layout extends frappe.ui.form.Layout{
	make_section(df = {}) {

		//console.log("frappe.ui.form.Layout.make_section() runned")
		super.make_section(df);
	}
}
class Section extends Section {
	constructor(parent, df, card_layout, layout) {
		console.log("Section constructor")
		super(parent, df, card_layout, layout);
	}
	make_head() {
		console.log("Section.make_head() runned")
		super.make_head();
	}
}
