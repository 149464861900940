/*
*  Translation Helper © 2023
*  Author:  Christian WERNER
*  Company: Werner.Solutions
*  Licence: Please refer to LICENSE file
*/


console.log("Translation Helper loaded");
import './form/controls/base_input.js';
import './form/layout.js'
import './form/section.js'

