(function () {

	frappe.ui.form.ControlData = class ControlData extends frappe.ui.form.ControlData {
		// sets Label based on Partenttype, Parent and Label
		set_label(label) {
			super.set_label(label);

			var icon = "";

			let key = this.df.parenttype + ": " + this.df.parent + ":" + this.df.label;
			let keyTranslated = __(key);
			if (keyTranslated != key) {
				let translated = keyTranslated;// || __(this.df.label) || "&nbsp;";
				if (super.label_span == undefined) {
					// Label doesnt exist
					return;
				}
				try {
					super.label_span.innerHTML =
						(icon ? '<i class="' + icon + '"></i> ' : "") + translated;
				}
				catch (e) {
					console.error("Error in Translation Helper", e);
				}
			}


		}
	}

	frappe.ui.form.ControlInput.prototype.__set_label = frappe.ui.form.ControlInput.prototype.set_label;

	frappe.ui.form.ControlInput.prototype.set_label = function (label) {

		this.__set_label(label)
		if (typeof this.label_span == "undefined") { return }
		var icon = "";

		let key = this.df.parenttype + ": " + this.df.parent + ":" + this.df.label;

		let keyTranslated = __(key);
		if (keyTranslated == key) {
			keyTranslated = undefined
		}
		let translated = keyTranslated || __(this.df.label) || "&nbsp;";

		this.label_span.innerHTML =
			(icon ? '<i class="' + icon + '"></i> ' : "") + translated;
	}



})();
